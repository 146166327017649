/* storybook-check-ignore */

/**
 * Clean up this file after initial release
 */
import { Button, Flex, Icon } from '@opendoor/bricks/core';

import { Quote } from 'components/exclusives/landing/components/section/templates/CarouselSectionTemplate/CarouselSectionTemplate';

export type CarouselButtonsProps = {
  animate: boolean;
  numHiddenCards: number;
  quotes: Quote[];
  scrollCards: (direction: 'next' | 'prev') => void;
};

export const CarouselButtons = ({
  animate,
  numHiddenCards,
  quotes,
  scrollCards,
}: CarouselButtonsProps) => {
  return (
    <Flex display={['none', null, 'flex']} flexDirection="row">
      <Button
        variant="icon"
        mr={5}
        onClick={() => scrollCards('prev')}
        disabled={animate || numHiddenCards == 0}
        aria-label="View previous review"
        analyticsName="hp-view-previous-review"
      >
        <Icon name="arrow-left" size={24} />
      </Button>
      <Button
        variant="icon"
        onClick={() => scrollCards('next')}
        disabled={animate || numHiddenCards == quotes.length - 1}
        aria-label="View next review"
        analyticsName="hp-view-next-review"
      >
        <Icon name="arrow-right" size={24} />
      </Button>
    </Flex>
  );
};
