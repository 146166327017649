import { Heading, HeadingProps } from '@opendoor/bricks/core';

export type HeroTitleProps = Omit<HeadingProps, 'as'>;

export const HeroTitle = (props: HeroTitleProps) => {
  return (
    <Heading
      as="h1"
      color="warmgrey950"
      css={{ b: { color: 'var(--colors-brand50)', fontWeight: 500 } }}
      fontWeight="medium"
      m="0"
      size="230"
      {...props}
    />
  );
};
