// storybook-check-ignore
import { Image, ImageProps } from '@opendoor/bricks/core';

type ResponsivePictureProps = Omit<ImageProps, 'fetchpriority'> & {
  widths?: [number, number, number];
  pictureProps?: React.HTMLAttributes<HTMLPictureElement>;
};

/**
 *
 * A simplistic convenience wrapper for imgix images to make them responsive.
 *
 * In contrast to other picture components (i.e. `Picture` and `ImgixPicture`),
 * this is much simplier by passing through props to an `Image` bricks component.
 */
export function ResponsivePicture({
  alt,
  widths = [768, 1200, 1800], // 768, 1200, 1800 is good for many full-width images
  loading = 'lazy',
  src,
  pictureProps,
  ...rest
}: ResponsivePictureProps) {
  let srcSet = '';
  if (src?.includes('?')) {
    srcSet = src + '&auto=compress,format&w=';
  } else {
    srcSet = src + '?auto=compress,format&w=';
  }

  return (
    <picture {...pictureProps}>
      <source srcSet={`${srcSet}${widths[0]}`} media="(max-width: 767px)" />
      <source srcSet={`${srcSet}${widths[1]}`} media="(min-width: 768px) and (max-width: 1199px)" />
      <source srcSet={`${srcSet}${widths[2]}`} media="(min-width: 1200px)" />
      <Image
        alt={alt}
        loading={loading}
        fetchpriority={loading === 'lazy' ? 'low' : 'high'}
        src=""
        {...rest}
      />
    </picture>
  );
}
