import { forwardRef } from 'react';

import { Flex, FlexProps } from '@opendoor/bricks/core';

export type SectionProps = Omit<FlexProps, 'as'> & {
  isSubsection?: boolean;
};

export const Section = forwardRef<HTMLDivElement, SectionProps>(
  ({ isSubsection, ...restOfProps }, forwardedRef) => (
    <Flex
      as="section"
      py={[isSubsection ? 64 : 96, null, null, isSubsection ? 96 : 160]}
      overflow="hidden"
      ref={forwardedRef}
      {...restOfProps}
    />
  ),
);

Section.displayName = 'Section';
