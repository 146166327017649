import { ReactNode } from 'react';

import { Flex } from '@opendoor/bricks/core';

import { Hero } from 'components/exclusives/landing/components/hero/Hero';
import { HeroContainer } from 'components/exclusives/landing/components/hero/HeroContainer';
import { HeroDescription } from 'components/exclusives/landing/components/hero/HeroDescription';
import { HeroTitle } from 'components/exclusives/landing/components/hero/HeroTitle';

export type HeroProps = {
  actions?: ReactNode;
  description?: ReactNode;
  image: ReactNode;
  title: ReactNode;
};

export const HeroTemplate = ({ actions, description, image, title }: HeroProps) => {
  return (
    <Hero pt={[40, null, 64, 72]}>
      <HeroContainer px={[0, 0, 0, 0]}>
        <Flex
          alignItems="center"
          display="grid"
          gap={32}
          gridTemplateColumns={['1fr', null, 'repeat(2, 1fr)']}
        >
          <Flex flexDirection="column" gap={[32, null, 48]} padding={[32, null, 64]}>
            <Flex flexDirection="column" gap={[4, null, 7]} maxWidth={['unset', null, 624]}>
              <HeroTitle>{title}</HeroTitle>
              {!!description && <HeroDescription>{description}</HeroDescription>}
            </Flex>

            {actions && (
              <Flex
                flexGrow={1}
                maxWidth={['unset', null, 520]}
                mx={[0, null, 'auto', 0]}
                width="100%"
              >
                {actions}
              </Flex>
            )}
          </Flex>

          {image}
        </Flex>
      </HeroContainer>
    </Hero>
  );
};
