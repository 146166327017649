import { Box, BoxProps } from '@opendoor/bricks/core';

export type SectionContainerProps = BoxProps;

export const SectionContainer = (props: SectionContainerProps) => {
  return (
    <Box
      mx="auto"
      maxWidth="var(--content-max-width)"
      px={[32, null, null, 64]}
      width="100%"
      {...props}
    />
  );
};
