import { Text, TextProps } from '@opendoor/bricks/core';

export type HeroDescriptionProps = Omit<TextProps, 'as'>;

export const HeroDescription = (props: HeroDescriptionProps) => {
  return (
    <Text
      as="p"
      color="warmgrey950"
      css={{ b: { color: 'var(--colors-brand50)', fontWeight: 500 } }}
      size="140"
      {...props}
    />
  );
};
