/* storybook-check-ignore */

/**
 * Clean up this file after initial release
 */
import { ReactNode } from 'react';

import { Flex, FlexProps, Text } from '@opendoor/bricks/core';

export type CarouselCardProps = FlexProps & {
  name: string;
  quote: ReactNode;
  role: string;
};

export const CarouselCard = ({ name, quote, role, ...restOfProps }: CarouselCardProps) => {
  return (
    <Flex
      backgroundColor="white0"
      borderRadius="roundedSmooth"
      boxShadow="0px 12px 24px 0px rgba(0, 0, 0, 0.08)"
      flexShrink={0}
      flexDirection="column"
      gap={24}
      justifyContent="space-between"
      maxWidth={520}
      minHeight={[345, null, 440]}
      p={48}
      ml={[16, null, 32]}
      scrollSnapAlign="start"
      width="calc(100% - 32px)"
      {...restOfProps}
    >
      <Flex flexDirection="column" gap={3}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64" width={64}>
          <path
            fill="#DEDAD7"
            d="M21.977 11.99a2 2 0 0 0-.72.153s-3.832 1.563-7.447 5.81S6.667 29.237 6.667 40c0 .098.007.195.02.292A11.333 11.333 0 0 0 18 52a11.333 11.333 0 1 0-6.388-20.693c1.158-4.937 3.218-8.378 5.245-10.76 3.052-3.586 5.885-4.69 5.885-4.69a2 2 0 0 0-.765-3.867Zm28 0a2 2 0 0 0-.72.153s-3.832 1.563-7.447 5.81S34.667 29.237 34.667 40c0 .098.006.195.02.292A11.333 11.333 0 0 0 46 52a11.334 11.334 0 1 0-6.388-20.693c1.158-4.937 3.218-8.378 5.245-10.76 3.052-3.586 5.885-4.69 5.885-4.69a2 2 0 0 0-.765-3.867Z"
          />
        </svg>

        <Text color="warmgrey950" size="150" lineHeight="130">
          {quote}
        </Text>
      </Flex>

      <Flex flexDirection="column" gap={3}>
        <Text color="warmgrey950" fontWeight="medium" size="130">
          {name}
        </Text>
        <Text color="warmgrey950" size="130">
          {role}
        </Text>
      </Flex>
    </Flex>
  );
};
