import { ReactNode } from 'react';

import { Flex } from '@opendoor/bricks/core';

import { Section, SectionProps } from 'components/exclusives/landing/components/section/Section';
import { SectionContainer } from 'components/exclusives/landing/components/section/SectionContainer';
import { SectionDescription } from 'components/exclusives/landing/components/section/SectionDescription';
import { SectionTitle } from 'components/exclusives/landing/components/section/SectionTitle';

export type CalloutSectionTemplateProps = Omit<SectionProps, 'title'> & {
  actions?: ReactNode;
  description?: ReactNode;
  image: ReactNode;
  title?: ReactNode;
};

export const CalloutSectionTemplate = ({
  actions,
  description,
  image,
  title,
  ...sectionProps
}: CalloutSectionTemplateProps) => {
  return (
    <Section {...sectionProps}>
      <SectionContainer>
        <Flex
          backgroundColor="warmgrey100"
          // @ts-expect-error - This is a known deviation from the design system.
          borderRadius={40}
          display="grid"
          alignItems="center"
          gridTemplateColumns={['1fr', null, 'repeat(2, 1fr)']}
          gap={32}
          p={24}
          px={[0, null, 24]}
        >
          <Flex
            flexDirection="column"
            gap={[24, null, null, 40]}
            py={40}
            px={[40, null, 0]}
            pl={[40, null, 40]}
          >
            <Flex flexDirection="column" gap={[5, null, null, 7]}>
              <SectionTitle>{title}</SectionTitle>

              {!!description && <SectionDescription>{description}</SectionDescription>}
            </Flex>

            {!!actions && <Flex gap={16}>{actions}</Flex>}
          </Flex>

          <Flex
            borderRadius="roundedSmooth"
            borderTopRadius={['none', null, 'roundedSmooth']}
            overflow="hidden"
          >
            {image}
          </Flex>
        </Flex>
      </SectionContainer>
    </Section>
  );
};
