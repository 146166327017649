import { Heading, HeadingProps } from '@opendoor/bricks/core';

export type SectionLabelProps = Omit<HeadingProps, 'as'> & {
  /**
   * Determines whether to use `h2` or `h3` as the heading element.
   *
   * If the title is being used in a section that's a child of
   * another section, we want to render this as an `h3` instead of
   * an `h2`.
   */
  isSubsection?: boolean;
};

export const SectionLabel = ({ isSubsection, ...restOfProps }: SectionLabelProps) => {
  return (
    <Heading
      as={isSubsection ? 'h4' : 'h3'}
      color={isSubsection ? 'warmgrey800' : 'brand50'}
      fontWeight="medium"
      m={0}
      size="150"
      {...restOfProps}
    />
  );
};
