/* storybook-check-ignore */
import { css, Global } from '@emotion/react';
import { novo } from '@opendoor/bricks/theme';
import { media } from '@opendoor/bricks/theme/legacy/foundations/mediaBreakpoints';
import { sizes as textSizes } from '@opendoor/bricks/theme/novo/components/text';

import Headerv2 from 'components/exclusives/layout/Headerv2/Headerv2';
import { BaseLayout, BaseLayoutProps } from 'components/exclusives/layouts/BaseLayout';
import ReturnExperienceBanner from 'components/landing-pages-v2/ReturnExperienceBanner';
import { AccountContextProvider } from 'components/shared/AccountConnector';

export type LandingLayoutProps = BaseLayoutProps;

export const LandingLayout = ({
  children,
  title = 'Opendoor Exclusives',
  ...restOfProps
}: LandingLayoutProps) => {
  return (
    <BaseLayout
      theme="novo"
      themeOverrides={{
        components: {
          Text: {
            sizes: {
              ...novo.components.Text.sizes,
              ...UpdatedTextSizes,
            },
          },
          Heading: {
            sizes: {
              ...novo.components.Heading.sizes,
              ...UpdatedTextSizes,
            },
          },
        },
      }}
      title={title}
      {...restOfProps}
    >
      <Global styles={globalStyles} />
      <ReturnExperienceBanner />
      <Headerv2
        hasBorderBottom={false}
        isSearchHidden
        maxWidth="var(--header-max-width)"
        logoHref="/exclusives"
      />
      <AccountContextProvider>{children}</AccountContextProvider>
    </BaseLayout>
  );
};

const globalStyles = css`
  :root {
    --header-max-width: 82rem;
    --content-max-width: 82rem;
  }

  html,
  body,
  #__next {
    padding: 0;
    margin: 0;
  }

  ${media.largerThan.LG} {
    :root {
      --header-max-width: 1440px;
      --content-max-width: 1440px;
    }
  }

  ${media.largerThan.XL} {
    :root {
      --header-max-width: 1440px;
      --content-max-width: 1440px;
    }
  }

  ${media.largerThan.XXL} {
    :root {
    }
  }
`;

/**
 * We're creating theme overrides for Novo's Text and Heading components
 * to fix responsive sizing issues since the current theme only supports
 * two breakpoints which isn't ideal for landing pages.
 *
 * This solution was copied from Opendoor's new landing page.
 * @see `src/components/landing-pages/Layout.tsx`
 */
const UpdatedTextSizes: Partial<typeof textSizes> = {
  150: {
    fontSize: ['16px', null, '20px', '24px'],
    letterSpacing: ['-0.224px', '-0.48px', '-0.672px'],
    lineHeight: ['130%', null, '120%', '112%'],
  },
  210: {
    fontSize: ['28px', null, '40px', '48px'],
    letterSpacing: ['-0.784px', '-1.2px', '-1.44px'],
    lineHeight: ['110%', null, '100%'],
  },
  220: {
    fontSize: ['32px', null, '48px', '56px'],
    letterSpacing: ['-0.896px', null, '-1.44px', ' -1.904px'],
    lineHeight: ['110%', null, '100%'],
  },
  230: {
    fontSize: ['32px', null, '48px', '64px'],
    letterSpacing: ['-0.896px', null, '-1.44px', '-2.176px'],
    lineHeight: ['110%', null, '100%'],
  },
};
