import { Heading, HeadingProps } from '@opendoor/bricks/core';

export type SectionTitleProps = Omit<HeadingProps, 'as'> & {
  /**
   * Determines whether to use `h2` or `h3` as the heading element.
   *
   * If the title is being used in a section that's a child of
   * another section, we want to render this as an `h3` instead of
   * an `h2`.
   */
  isSubsection?: boolean;
};

export const SectionTitle = ({ isSubsection, ...restOfProps }: SectionTitleProps) => {
  return (
    <Heading
      as={isSubsection ? 'h3' : 'h2'}
      color="warmgrey950"
      css={{ b: { color: 'var(--colors-warmgrey700)', fontWeight: 500 } }}
      fontWeight="medium"
      m="0"
      size="220"
      {...restOfProps}
    />
  );
};
