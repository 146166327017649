/* storybook-check-ignore */

/**
 * Clean up this file after initial release
 */
import { ReactNode, useState } from 'react';

import { css } from '@emotion/react';
import { Flex, Text } from '@opendoor/bricks/core';

import { useMediaQueryContext } from 'components/exclusives/contexts/MediaQueryContext';
import { Section, SectionProps } from 'components/exclusives/landing/components/section/Section';
import { SectionContainer } from 'components/exclusives/landing/components/section/SectionContainer';
import { SectionTitle } from 'components/exclusives/landing/components/section/SectionTitle';
import { CarouselButtons } from 'components/exclusives/landing/components/section/templates/CarouselSectionTemplate/CarouselButtons';
import { CarouselCard } from 'components/exclusives/landing/components/section/templates/CarouselSectionTemplate/CarouselCard';
import {
  ANIMATION_DURATION,
  disappearAnimation,
  reverseDisappearAnimation,
  reverseSlideAnimation,
  slideAnimation,
} from 'components/landing-pages-v2/CarouselSection/CarouselAnimations';

export type Quote = {
  author: {
    name: string;
    role: string;
  };
  quote: ReactNode;
};

export type CarouselSectionTemplateProps = SectionProps & {
  featuredIn: ReactNode;
  quotes: Quote[];
  title: ReactNode;
};

/**
 * Most of the logic is copied from `src/components/landing-pages/CarouselSection/CarouselCards.tsx`.
 *
 * @note We are OKAY with this template being messy because we're on a tight deadline to get this release.
 */
export const CarouselSectionTemplate = ({
  featuredIn,
  quotes = [],
  title,
  ...sectionProps
}: CarouselSectionTemplateProps) => {
  const [animate, setAnimate] = useState(false);
  const [numHiddenCards, setNumHiddenCards] = useState(0);
  const [scrollDirection, setScrollDirection] = useState<'next' | 'prev' | null>(null);
  const { isDesktop } = useMediaQueryContext();

  const scrollCards = (direction: 'next' | 'prev') => {
    setAnimate(true);
    setScrollDirection(direction);
    if (direction === 'prev') {
      setNumHiddenCards(Math.max(numHiddenCards - 1, 0));
    }

    // Timeout has to match duration on animation CSS
    setTimeout(() => {
      setAnimate(false);

      if (direction === 'next') {
        setNumHiddenCards(Math.min(numHiddenCards + 1, quotes.length - 1));
      }
      setScrollDirection(null);
    }, ANIMATION_DURATION * 1000);
  };

  const getAnimation = (cardIdx: number) => {
    if (cardIdx == 0) {
      return scrollDirection === 'next' ? disappearAnimation : reverseDisappearAnimation;
    }
    return scrollDirection === 'next' ? slideAnimation : reverseSlideAnimation;
  };

  return (
    <Section {...sectionProps}>
      <SectionContainer>
        <Flex flexDirection="column" gap={24}>
          <Flex
            display="grid"
            gridTemplateColumns={['1fr', null, 'repeat(2, 1fr)']}
            alignItems="center"
            justifyItems="center"
            gap={[0, null, 40]}
          >
            <Flex
              order={0}
              overflowX={['auto', null, 'hidden']}
              // hide scrollbar
              css={{
                '&::-webkit-scrollbar': { width: 0 },
              }}
              scrollSnapType={['inline mandatory', null, 'unset']}
              overscrollBehaviorX="contain"
              scrollPaddingLeft={32}
              py={[32, null, 48]}
              px={20}
              width={['calc(100% + 64px)', null, '100%']}
            >
              {quotes.map((quote, index) => {
                const visibleCardIdx = index - numHiddenCards;
                return (
                  visibleCardIdx >= 0 && (
                    <CarouselCard
                      css={css`
                        ${animate ? getAnimation(visibleCardIdx) : null}

                        ${isDesktop &&
                        `&:nth-child(n+${animate ? 3 : 2}) {
                          visibility: hidden;
                        }`}
                      `}
                      key={index}
                      name={quote.author.name}
                      quote={quote.quote}
                      role={quote.author.role}
                      visibility={['visible', null, null]}
                    />
                  )
                );
              })}
            </Flex>
            <Flex order={[-1, -1, 1]} flexDirection="column" gap={40}>
              <SectionTitle>{title}</SectionTitle>
              <CarouselButtons
                animate={animate}
                numHiddenCards={numHiddenCards}
                quotes={quotes}
                scrollCards={scrollCards}
              />
            </Flex>
          </Flex>

          <Flex alignItems="center" flexDirection="column" gap={5} justifyContent="center">
            <Text color="warmgrey800" fontWeight="medium" size="120">
              Opendoor is featured in
            </Text>
            <Flex alignItems="center" flexWrap="wrap" gap={32} justifyContent="center">
              {featuredIn}
            </Flex>
          </Flex>
        </Flex>
      </SectionContainer>
    </Section>
  );
};
