import { Text, TextProps } from '@opendoor/bricks/core';

export type SectionDescriptionProps = Omit<TextProps, 'as'> & {
  /**
   * Determines whether to use `h2` or `h3` as the heading element.
   *
   * If the title is being used in a section that's a child of
   * another section, we want to render this as an `h3` instead of
   * an `h2`.
   */
  isSubsection?: boolean;
};

export const SectionDescription = (props: SectionDescriptionProps) => {
  return <Text as="p" color="warmgrey950" size="150" {...props} />;
};
