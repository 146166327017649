import { ReactNode } from 'react';

import { Flex, Heading } from '@opendoor/bricks/core';

import { Section, SectionProps } from 'components/exclusives/landing/components/section/Section';
import { SectionContainer } from 'components/exclusives/landing/components/section/SectionContainer';

export type GroupSectionTemplateProps = Omit<SectionProps, 'label' | 'title'> & {
  label?: ReactNode;
  title: ReactNode;
};

export const GroupSectionTemplate = ({
  children,
  label,
  title,
  ...sectionProps
}: GroupSectionTemplateProps) => {
  return (
    <Section flexDirection="column" pb={[0, null, null, 0]} {...sectionProps}>
      <SectionContainer>
        <Flex
          alignItems="center"
          flexDirection="column"
          gap={[3, null, 5]}
          justifyContent="center"
          maxWidth={632}
          mx="auto"
        >
          <Heading as="h2" fontWeight="medium" order={2} m={0} size="220" textAlign="center">
            {title}
          </Heading>
          {!!label && (
            <Heading
              as="h3"
              color="warmgrey800"
              fontWeight="medium"
              order={1}
              m={0}
              size="150"
              textAlign="center"
            >
              {label}
            </Heading>
          )}
        </Flex>
      </SectionContainer>
      {children}
    </Section>
  );
};
