import { ReactNode } from 'react';

import { Flex, FlexProps } from '@opendoor/bricks/core';

import { Section, SectionProps } from 'components/exclusives/landing/components/section/Section';
import { SectionContainer } from 'components/exclusives/landing/components/section/SectionContainer';
import { SectionDescription } from 'components/exclusives/landing/components/section/SectionDescription';
import { SectionLabel } from 'components/exclusives/landing/components/section/SectionLabel';
import { SectionTitle } from 'components/exclusives/landing/components/section/SectionTitle';

export type TextSectionTemplateProps = SectionProps & {
  description?: ReactNode;
  image?: ReactNode;
  /**
   * Enable if this is used in a `<GroupSection />`.
   */
  isSubsection?: boolean;
  label?: ReactNode;
  meta?: ReactNode;
  title: ReactNode;
  textContainerProps?: FlexProps;
};

export const TextSectionTemplate = ({
  description,
  label,
  image,
  isSubsection,
  meta,
  title,
  textContainerProps,
  ...restOfProps
}: TextSectionTemplateProps) => {
  return (
    <Section isSubsection={isSubsection} {...restOfProps}>
      <SectionContainer>
        <Flex
          alignItems="center"
          display="grid"
          gap={40}
          gridTemplateColumns={['1fr', null, 'repeat(2, 1fr)']}
        >
          <Flex flexDirection="column" {...textContainerProps}>
            <SectionTitle isSubsection={isSubsection} order={2}>
              {title}
            </SectionTitle>

            {!!label && (
              <SectionLabel isSubsection={isSubsection} mb={[6, null, 9]} order={1}>
                {label}
              </SectionLabel>
            )}

            {!!description && (
              <SectionDescription mt={[4, null, 7]} order={3}>
                {description}
              </SectionDescription>
            )}

            {!!meta && (
              <Flex mt={[8, null, 10]} order={4}>
                {meta}
              </Flex>
            )}
          </Flex>

          <Flex height="100%" justifyContent="center">
            {image}
          </Flex>
        </Flex>
      </SectionContainer>
    </Section>
  );
};
